<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>
    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/database' }">
              青海省中小企业数据库</el-breadcrumb-item
            >
            <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
            <el-breadcrumb-item class="" style="color: #ff6618"
              >企业详情</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>
    </div>

    <div style="padding-bottom: 65px; background-color: #fafafa">
      <div style="">
        <div class="max_width min_width">
          <section class="bgfff" style="padding: 30px">
            <div class="flex" style="margin-bottom: 30px; align-items: center">
              <div
                style="
                  width: 4px;
                  height: 19px;
                  background: #ff6417;
                  border-radius: 2px; 
                "
                class="marr_12"
              ></div>
              <div class="size_24">{{ list_d.name }}</div>
            </div>
            <div class="flex">
              <div
                class="size_18 text_center  "
                v-for="(item, index) in titlelist"
                :key="item.id"
                :class="detailsIndex === index ? 'act_title' : 'details_title'"
                @click="switch_to(index)"
              >
                {{ item.name }}
              </div>
            </div>
          </section>
        </div>
      </div>

      <div v-show="detailsIndex == 0">
        <div style="background-color: #fafafa">
          <div class="max_width min_width">
            <section class="bgfff" style="padding: 0px 30px">
              <div class="flex marl_20">
                <div class="wid_33 size_18 fnf">
                  法定代表人：{{
                    list_d.legalPersonName ? list_d.legalPersonName : "-"
                  }}
                </div>
                <div class="wid_33 size_18 fnf">
                  经营状态：{{ list_d.regStatus ? list_d.regStatus : "-" }}
                </div>
                <div class="wid_33 size_18 fnf">
                  成立日期：{{
                    list_d.estiblishTime ? list_d.estiblishTime : "-"
                  }}
                </div>
              </div>
              <div class="flex marl_20" style="padding-top: 30px">
                <div class="wid_33 size_18 fnf">
                  电话：{{ list_d.phone ? list_d.phone : "-" }}
                </div>
                <div class="wid_33 size_18 fnf">
                  邮箱：{{ list_d.email ? list_d.email : "-" }}
                </div>

                <div class="wid_33 size_18 fnf">
                  网址：{{ list_d.url ? list_d.url : "-" }}
                </div>
              </div>
              <div class="flex marl_20" style="padding-top: 30px">
                <div class="wid_33 size_18 fnf">
                  注册资本：{{ list_d.regCapital ? list_d.regCapital : "-" }}
                </div>
                <div class="wid_33 size_18 fnf">
                  实缴资本：{{
                    list_d.actualCapital ? list_d.actualCapital : "-"
                  }}
                </div>
                <div class="wid_33 size_18 fnf">
                  营业期限：{{
                    list_d.businessTerm ? list_d.businessTerm : "-"
                  }}
                </div>
              </div>
              <div class="flex marl_20" style="padding-top: 30px">
                <div class="wid_33 size_18 fnf">
                  统一社会信用代码：{{
                    list_d.creditCode ? list_d.creditCode : "-"
                  }}
                </div>
                <div class="wid_33 size_18 fnf">
                  纳税人识别号：{{ list_d.taxNumber ? list_d.taxNumber : "-" }}
                </div>
                <div class="wid_33 size_18 fnf">
                  注册号：{{ list_d.regNo ? list_d.regNo : "-" }}
                </div>
                <!-- <div class=" wid_33 size_18 fnf">企业类型：{{list_d.registerType}}</div> -->
                <!-- <div class=" wid_33 size_18 fnf">行业：{{list_d.industry}}</div> -->
              </div>
              <div class="flex marl_20" style="padding-top: 30px">
                <div class="wid_33 size_18 fnf">
                  企业类型：{{
                    list_d.registerType ? list_d.registerType : "-"
                  }}
                </div>
                <div class="wid_33 size_18 fnf">
                  行业：{{ list_d.industry ? list_d.industry : "-" }}
                </div>
                <div class="wid_33 size_18 fnf">
                  组织机构代码：{{ list_d.organCode ? list_d.organCode : "-" }}
                </div>
              </div>
              <div class="flex marl_20" style="padding-top: 30px">
                <div class="wid_33 size_18 fnf">
                  企业曾用名：{{
                    list_d.historyNames ? list_d.historyNames : "-"
                  }}
                </div>
                <div class="wid_33 size_18 fnf">
                  参保人数：{{
                    list_d.socialStaffNum ? list_d.socialStaffNum : "-"
                  }}
                </div>
                <div class="wid_33 size_18 fnf">
                  核准日期：{{
                    list_d.approvedTime ? list_d.approvedTime : "-"
                  }}
                </div>
              </div>
              <div class="flex marl_20" style="padding-top: 30px">
                <div class="size_18 fnf">
                  最新年报地址：{{
                    list_d.annualReport ? list_d.annualReport : "-"
                  }}
                </div>
              </div>
              <div class="flex marl_20" style="padding-top: 30px">
                <div class="size_18 fnf">
                  地址：{{ list_d.regLocation ? list_d.regLocation : "-" }}
                </div>
              </div>
            </section>
            <div style="background-color: #fafafa">
              <div class="max_width min_width">
                <section class="bgfff" style="padding: 30px">
                  <div class="size_18 fnf marl_20">
                    经营范围：{{
                      list_d.businessScope ? list_d.businessScope : "-"
                    }}
                  </div>
                </section>
              </div>
            </div>

            <div class="size_18 honorbox max_width min_width">
              <div>企业荣誉</div>
              <div class="mart_30 flex align_center" v-if="honorlist">
                <div
                  class="marr_30"
                  v-if="(currentSlide - 1) % honorlist.length > 0"
                >
                  <img
                    @click="left()"
                    src="../../assets/image/left.png"
                    style="width: 100px;"
                    alt=""
                  />
                </div>
                <div class="flex honorimgbox">
                  <div
                    class="text_center marr_30"
                    v-for="(item, index) in honorlist"
                    :key="item.id"
                    :style="{
                      transform: `translateX(-${currentSlide * 120}%)`,
                    }"
                  >
                    <img
                      :src="item.img"
                      alt=""
                      style="width: 200px; height: 200px"
                      @click="previewImg(item.img)"
                    />
                    <div>{{ item.title }}</div>
                  </div>
                </div>
                <div
                  class="marl_20"
                  v-if="
                    honorlist.length > 7 &&
                    (currentSlide + 1) % honorlist.length !=
                      honorlist.length - currentSlide
                  "
                >
                  <img
                    src="../../assets/image/left.png"
                    @click="right()"
                    style="transform: rotate(180deg); width: 100px"
                    alt=""
                  />
                </div>
              </div>
              <div v-else>
                <el-empty description="暂无数据" :image-size="200" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 企业简介 -->
      <div v-show="detailsIndex === 1" class="synopsisbox max_width min_width">
        <div class="synopsis" style="padding: 0px 30px">
          <div class="size_18 " style="margin-left: 20px;">公司简介</div>
          <div
            class="mart_30"
            v-if="list_d.content"
            v-html="list_d.content"
            style="background-color: #fff;margin-left: 20px;"
          ></div>
          <div v-else>
            <el-empty description="暂无数据" :image-size="200" />
          </div>
        </div>

        <div class="synopsis max_width min_width" style="margin-top: 20px; padding: 30px;">
          <div class="size_18" style="margin-left: 20px;">主营产品</div>
          <div
            class="mart_30"
            
            v-if="list_d.main_product"
            style="background-color: #fff;margin-left: 20px;"
            v-html="list_d.main_product"
          ></div>
          <div v-else>
            <el-empty description="暂无数据" :image-size="200" />
          </div>
        </div>
      </div>

      <!-- 企业动态 -->
      <div v-show="detailsIndex == 2" class="dynamicbox max_width min_width">
        <div v-if="dynamic.length != 0">
          <div class="dynamic_outer">
            <div v-for="(item, index) in dynamic" :key="index" class="dynamic">
              <div class="size_18 marb_20">
                {{ item.title }}
              </div>
              <div class="font_16 marb_20">
                发布时间：{{ item.create_time }}
              </div>
              <div class="marb_20" style="border-bottom: 1px solid #808080">
                <div class="marb_20 flex">
                  <img
                    v-for="(i, v) in imglist"
                    :src="i.img"
                    @click="previewImg(i.img)"
                    style="width: 120px; height: 120px; margin-right: 30px"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div v-if="dynamic.length > 0">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="paginations.page_index"
                :page-sizes="paginations.page_sizes"
                :page-size="paginations.page_size"
                :layout="paginations.layout"
                :total="paginations.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>

        <div v-else>
          <el-empty description="暂无数据" :image-size="200" />
        </div>
      </div>
    </div>

    <app-footer></app-footer>

    <div class="lookimg" style="" v-if="test" @click="pp">
      <div style="">
        <img :src="preImg" class="img" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  data() {
    return {
      offsetHeight: 0,
      details: [],
      id: 0,
      list_d: {},
      info: {},
      test: false,
      preImg: "",
      detailsIndex: 0,
      alldynamic: [],
      imglist: [],
      honorlist: [],
      titlelist: [
        { id: 0, name: "企业详情" },
        { id: 1, name: "企业简介" },
        { id: 2, name: "企业动态" },
      ],
      dynamic: [],
      currentSlide: 0,
      //分页
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.infolist_d();
    this.gethonor();
  },
  methods: {
    gethonor() {
      let data = {
        info_id: this.id,
      };
      this.$post("enterprise_info_honour", data).then((res) => {
        console.log(res, "enterprise_info_honour");
        this.honorlist = res.data;
      });
    },

    left() {
      let dom = document.querySelector(".honorimgbox");
      let total = dom.children.length;
      console.log(total, "total");

      if (this.currentSlide - 5 < total && this.currentSlide - 5 > -5) {
        this.currentSlide = (this.currentSlide - 1) % total;
      }
    },
    right() {
      let dom = document.querySelector(".honorimgbox");
      let total = dom.children.length;
      if (this.currentSlide + 5 < total) {
        this.currentSlide = (this.currentSlide + 1) % total;
      }
    },

    pp() {
      this.test = false;
    },
    previewImg(img) {
      console.log("789456");
      this.test = true;
      this.preImg = img;
    },
    switch_to(index) {
      console.log(index);
      this.detailsIndex = index;
      if (index == 2) {
        this.info_News();
      }
    },

    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },
    infolist_d() {
      let data = {
        id: this.id,
      };
      this.$get("info_lst_d", data).then((res) => {
        console.log(res, "re789s");
        this.list_d = res.result;
      });
    },

    info_News(item) {
      let data = {
        info_id: this.id,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
      };
      this.$post("enterprise_info_news", data).then((res) => {
        console.log(res, "re789s");
        this.alldynamic = res.data;
        if (this.alldynamic) {
          this.alldynamic.forEach((item) => {
            if (item.img) {
              this.imglist = JSON.parse(item.img);
              console.log(JSON.parse(item.img), "JSON.parse(item.img)");
            }
          });
        }

        console.log(this.imglist, "this.imglist");
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }
        //设置默认的分页数据
        if (this.alldynamic.length != 0) {
          this.dynamic = this.alldynamic.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.dynamic = [];
        }

        console.log(this.dynamic, "dynamic");
      });
    },
    // 分页
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.info_News();
      this.dynamic = this.alldynamic.filter((item, index) => {
        return index < page_size;
      });
    },

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      this.paginations.page_index = page;
      this.info_News(page);
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.alldynamic[i]) {
          tables.push(this.alldynamic[i]);
        }

        this.dynamic = tables;
      }
    },
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
};
</script>
<style scoped lang="less">
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}
.wid_33 {
  width: 33%;
}
.details_title {
  width: 220px;
  height: 50px;
  line-height: 50px;
}
.act_title {
  width: 220px;
  height: 50px;
  line-height: 50px;
  // font-weight: bold;
   background: rgba(#FF7129, 0.18); 
}
.synopsisbox {
  // width: 1520px;
  // opacity: 0.18;
  padding: 0px 0px 100px 0px;
  margin: 0 auto;
  .synopsis {
    // width: 1520px;
    margin: 0px auto;
    background: #fff;
    // box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
  }
}

.dynamic_outer {
  // width: 1520px;
  // opacity: 0.18;
  background: #fff;
  padding: 20px;
  
  // background: #3a61c2;
  // box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
}
.lookimg {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;

  .img {
    width: 400px;
    height: 400px;
    display: block;
    margin: 0 auto;
    transform: translate(0px, 50%);
  }
}
.honorbox {
  margin-top: 20px;
  padding: 30px;
  background: #fff;
  // margin-left: 20px;
}
.dynamicbox {
  // width: 1520px;
  // opacity: 0.18;
  padding: 0px 0px 100px 0px;
  margin: 0 auto;
  background: #fff;

  .dynamic_outer {
    // width: 1520px;
    // background: #fff;
    padding: 20px;
  }
}
::v-deep.el-pagination {
    text-align: center;
    padding: 20px 0px;
    background-color: #fff;
}
.honorimgbox {
  overflow: auto;
  height: 250px;
}
.honorimgbox::-webkit-scrollbar {
  width: 0;
}
</style>


